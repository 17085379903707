<template>
	<div class="waybillCollection">
		<el-row class="search">
			<el-col :span="2">
				<div class="jy_dictionary" @click="dictionShow">
					<i class="el-icon-notebook-1"></i>
				</div>
			</el-col>
			<el-col :span="22" class="search_button">
				<el-row>
					<el-col :span="24">
						<el-button v-if="btnexist('/operational/addRecord')" type="primary" @click="addCollectionWay">补录</el-button>
						<!-- <el-button type="primary" @click="batchupdate()">批量修改路单</el-button> -->
						<!-- <el-button type="primary" @click="batchadjust()">批量班次调整</el-button> -->
						<el-button v-if="btnexist('/operational/batchDelete')" type="primary" @click="batchdelete()">批量删除</el-button>
						<el-button v-if="btnexist('/operational/batchVerity')" type="primary" @click="batchaudit()">批量提交审核</el-button>
						<el-button v-if="btnexist('/operational/export')" type="primary" @click="onexport()">导出</el-button>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
		<!-- v-popover:jy_popover -->
		<jy-table
			max-height="595"
			ref="multipleTable"
			tooltip-effect="dark"
			@select-all="selectallchange"
			@select="selectchange"
			:data="dataList"
			@cell-mouse-enter="tableHover"
			:row-style="setRowStyle"
		>
			<jy-table-column type="index" label="序号" width="60" fixed="left" align="center"></jy-table-column>
			<jy-table-column type="selection" width="40" fixed="left"></jy-table-column>
			<jy-table-column prop="routeName" label="所属线路" min-width="100" fixed="left">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.routeName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="180" fixed="left">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.orgName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="operationDate" label="营运日期" min-width="100" fixed="left">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.operationDate"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="dirName" label="方向" min-width="100" fixed="left">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.dirName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="planVehicleNo" label="计划车牌号" min-width="100" fixed="left">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.planVehicleNo"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="actualVehicleNo" label="实际车牌号" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.actualVehicleNo"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="planDriverName" label="计划司机" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.planDriverName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="planJobNum" label="计划司机工号" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.planJobNum"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="actualDriverName" label="实际司机" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.actualDriverName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="actualJobNum" label="实际司机工号" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.actualJobNum"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="planDepartureTime" label="计划时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.planDepartureTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="beginTime" label="开始时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.beginTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="endTime" label="结束时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.endTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="runningTime" label="运行时长(min)" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.runningTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="shifts" label="班次" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.shifts"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="setMileage" label="设定线路里程(km)" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.setMileage"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="actualMileage" label="实际线路里程(km)" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.actualMileage"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="runMileage" label="运行里程(km)" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.runMileage"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="beginTimeDevice" label="开始时间依据" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.beginTimeDevice"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="endTimeDevice" label="结束时间依据" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.endTimeDevice"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="overSites" label="经过站点数" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.overSites"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="dumpStationNum" label="未经过站点数" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.dumpStationNum"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="shiftStatusName" label="班次状态" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.shiftStatusName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="adjReason" label="班次状态原因" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.adjReason"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="isScheduleShift" label="是否跨线路" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.isScheduleShift == '0' ? '否' : '是'"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="remark" label="备注" min-width="200">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.remark"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="scopeTime" label="准点误差范围" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.scopeTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="avgSpeed" label="平均速度(km/h)" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.avgSpeed"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="operateStateName" label="完成情况" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.operateStateName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="timeCardTemplateName" label="时间牌模板" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.timeCardTemplateName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="timeCardOrder" label="时间牌号" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.timeCardOrder"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="scheduler" label="排班操作员" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.scheduler"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="schedulerTime" label="排班操作时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.schedulerTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="schedulerAuditor" label="排班审核人员" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.schedulerAuditor"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="schedulerAuditTime" label="排班审核时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.schedulerAuditTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="schedulerAuditNotes" label="排班审核备注" min-width="200">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.schedulerAuditNotes"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="operateUser" label="路单操作人" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.operateUser"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="operateTime" label="路单操作时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.operateTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="operateRemark" label="路单操作备注" min-width="200">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.operateRemark"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="auditStateName" label="路单审核状态" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.auditStateName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="auditUser" label="路单审核人" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.auditUser"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="auditTime" label="路单审核时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.auditTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="auditRemark" label="路单审核备注" min-width="200">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.auditRemark"></popover>
				</template>
			</jy-table-column>
			<jy-operate v-bind:list="operateList" width="200"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<div class="jy_popover">
			<el-popover placement="top" title="路单异常" width="180" trigger="hover" ref="jy_popover">
				<div class="popover_content" v-for="item in statusDate" :key="item.name" v-show="item.errorId != 9">
					<div :style="{ background: item.color }" class="popover_color"></div>
					<div v-text="item.name"></div>
				</div>
			</el-popover>
		</div>
		<add-collection ref="addCollection" @updateList="oncheck"></add-collection>
		<batch-update-waybill ref="batchUpdateWaybill" @updateList="oncheck"></batch-update-waybill>
		<shift-adjust ref="shiftAdjust" @updateList="oncheck"></shift-adjust>
		<dirctor-show ref="dirctorShow"></dirctor-show>
		<track-replay ref="trackReplay"></track-replay>
	</div>
</template>
<script>
import addCollection from "@/components/pages/admin/waybillManagement/collection/waybillCollection/addCollection";
import batchUpdateWaybill from "@/components/pages/admin/waybillManagement/collection/waybillCollection/batchUpdateWaybill";
import shiftAdjust from "@/components/pages/admin/waybillManagement/collection/waybillCollection/shiftAdjust";
import dirctorShow from "@/components/pages/admin/waybillManagement/dirctorShow";
import trackReplay from "@/components/pages/admin/waybillManagement/trackReplay/trackReplay";
import popover from "@/components/pages/admin/common/popover";

export default {
	data() {
		return {
			statusDate: [],
			batchdeleteList: [],
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			operateList: []
		};
	},
	props: {
		formData: {
			type: Object,
			default: function () {
				return {};
			}
		}
	},
	filters: {
		tissueTypes(val) {
			if (val === 0) {
				return "待审核";
			} else if (val === 1) {
				return "审核不通过";
			} else if (val === 2) {
				return "审核通过";
			} else {
				return val;
			}
		}
	},
	watch: {},

	components: {
		addCollection,
		batchUpdateWaybill,
		shiftAdjust,
		dirctorShow,
		trackReplay,
		popover
	},

	computed: {},

	beforeMount() {},

	mounted() {},

	activated() {
		this.getList();
	},

	created() {
		this.getList();
		this.setOperateList();
	},

	methods: {
		setOperateList() {
			const vm = this;
			let l = [
				{
					name: "轨迹回放",
					icon: "el-icon-edit-outline",
					fun: this.trackReplay,
					isShow() {
						return vm.btnexist("/operational/trackPlayback");
					}
				},
				{
					name: "路单修改",
					icon: "el-icon-edit-outline",
					fun: this.addUdate,
					isShow: function (row) {
						if (parseInt(row.auditState) != 2 && vm.btnexist("/operational/update")) {
							return true;
						} else {
							return false;
						}
					}
				},
				{
					name: "班次调整",
					icon: "el-icon-edit-outline",
					fun: this.shiftAdjust,
					isShow: function (row) {
						if (row.errorId != "8") {
							if (parseInt(row.auditState) === 2) {
								return false;
							} else {
								//系统审核状态不能进行班次调整
								if (row.errorId && row.errorId.indexOf("9") != -1) {
									return false;
								} else {
									return true;
								}
							}
						} else {
							return false;
						}
					}
				},
				{
					name: "提交审核",
					icon: "el-icon-edit-outline",
					fun: this.onAudit,
					isShow: function (row) {
						if (parseInt(row.auditState) != 2 && vm.btnexist("/operational/verity")) {
							return true;
						} else {
							return false;
						}
					}
				},
				{
					name: "路单删除",
					icon: "el-icon-delete",
					fun: this.handleDelete,
					isShow() {
						return vm.btnexist("/operational/delete");
					}
				}
			];
			this.operateList = l;
		},
		// 查询数据
		getList() {
			let option = {
				...this.formData,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/waybillOperate/getList";
			this.$http.post(url, option).then(({ detail }) => {
				this.dataList = detail.list;
				this.total = detail.total;
				this.batchdeleteList = [];
				if (this.dataList.length === 0) {
					this.statusDate = [];
				}
			});
		},
		// 设置行内元素样式
		setRowStyle({ row }) {
			if (row.errors) {
				// return 'color:' + row.errors[0].color
				return { color: row.errors[0].color };
			}
		},
		// 查询表格
		oncheck() {
			this.getList();
			console.log("更新");
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 勾选数据
		selectchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		// 字典
		dictionShow() {
			this.$refs.dirctorShow.init();
		},
		tableHover(row) {
			//   console.log(row)
			if (row.errors) {
				this.statusDate = row.errors;
			}
		},
		// 补录
		addCollectionWay() {
			this.$refs.addCollection.init(this.formData, "add");
		},
		// 批量修改路单
		batchupdate() {
			if (this.batchdeleteList.length == 0) {
				this.$message({
					type: "warning",
					message: "请先选取数据"
				});
			} else {
				this.$refs.batchUpdateWaybill.init(this.batchdeleteList, "batch");
			}
		},
		// 修改路单
		addUdate(row) {
			this.$refs.addCollection.init(row, "update");
		},
		// 批量班次调整
		batchadjust() {
			if (this.batchdeleteList.length == 0) {
				this.$message({
					type: "warning",
					message: "请先选取数据"
				});
			} else {
				this.$refs.shiftAdjust.init(this.batchdeleteList, "batch");
			}
		},
		// 班次调整
		shiftAdjust(row) {
			this.$refs.shiftAdjust.init(row, "single");
		},
		// 批量删除
		batchdelete() {
			if (this.batchdeleteList.length == 0) {
				this.$message({
					type: "warning",
					message: "请先选取数据"
				});
			} else {
				this.$confirm("是否删除所选中的记录?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						let ids = [];
						this.batchdeleteList.forEach(item => {
							ids.push(item.waybillId);
						});
						let option = { ids: ids };
						this.deleteRecord(option);
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除"
						});
					});
			}
		},
		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该文件?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = { ids: [row.waybillId] };
					this.deleteRecord(option);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		deleteRecord(supplierIdAry) {
			this.$http.post("/waybillOperate/delete", supplierIdAry).then(data => {
				if (data.code == "0") {
					this.$message.success(data.msg);
					this.getList();
				} else {
					this.$message.error(data.msg);
				}
			});
		},
		// 批量提交审核
		batchaudit() {
			if (this.batchdeleteList.length == 0) {
				this.$message({
					type: "warning",
					message: "请先选取数据"
				});
			} else {
				let ids = [];
				this.batchdeleteList.forEach(item => {
					ids.push(item.waybillId);
				});
				let option = { ids: ids };
				this.auditRecord(option);
			}
		},
		// 提交审核
		onAudit(row) {
			let option = { ids: [row.waybillId] };
			this.auditRecord(option);
		},
		auditRecord(supplierIdAry) {
			this.$http.post("/waybillOperate/submitAudit", supplierIdAry).then(data => {
				if (data.code == "0") {
					this.$message.success(data.msg);
					this.getList();
				} else {
					this.$message.error(data.msg);
				}
			});
		},
		// 导出 funDownload
		onexport() {
			let url = "/waybillOperate/getListExport";
			let params = {
				...this.formData
			};
			let option = {
				type: "format",
				isBlod: true
			};
			this.$http.post(url, params, option).then(res => {
				console.log(res);
				if (res.status == 200) {
					this.$util.funDownload(res.data, "营运班次实录");
				} else {
					this.$message({
						message: "导出失败",
						type: "warning"
					});
				}
			});
		},
		// 轨迹回放
		trackReplay(option) {
			this.$refs.trackReplay.init(option, "waybill");
		},
		// 查看
		handlecheck(option) {
			this.$refs.addUpdateOrg.init(option, "view");
		},
		btnexist(name) {
			const parent = this.$parent.$parent.$parent;
			return parent.btnexist(name);
		}
	}
};
</script>
<style lang="scss" scoped>
.popover_content {
	display: flex;
	justify-content: flex-start;

	.popover_color {
		height: 15px;
		width: 15px;
		margin-right: 10px;
	}
}

.jy_dictionary {
	font-size: 30px;
	color: #456ea5;
	cursor: pointer;
}
</style>
