<template>
    <div class="add_update_role">
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTabelWidth" append-to-body>
            <div>
                <el-form ref="form" :model="form" label-width="120px">
                    <el-row class="search">
                        <el-col :span="18">
                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="定点专线名称:" prop="name" label-width="120px">
                                        <el-input v-model="form.name" placeholder="请输入"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item>
                                        <el-button type="primary" @click="oncheck">查询</el-button>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" plain @click="resetForm('form')">重置</el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-form>
                <div class="jy_table">
                    <el-table ref="multipleTable" tooltip-effect="dark" :data="dataList" header-cell-class-name="bg_color" border @cell-click="handleCurrentSelectChange" v-loading="loading">
                        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                        <!-- <el-table-column type="selection" width="70"></el-table-column> -->
                        <el-table-column prop="routeName" label="所属线路"></el-table-column>
                        <el-table-column prop="name" label="专线名称"></el-table-column>
                        <el-table-column prop="upMileage" label="上行里程（km)" min-width="100"></el-table-column>
                        <el-table-column prop="downMileage" label="下行里程(km)" min-width="100"></el-table-column>
                        <el-table-column prop="totalMileage" label="总里程（km）" min-width="100"></el-table-column>
                    </el-table>
                    <div class="jy_pagination">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="global.pageSizesList" :page-size="pageSize" :layout="global.pageLayout" :total="total"></el-pagination>
                    </div>
                </div>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            type: '',
            isCheck: false,
            title: '专线名称选择',
            form: {
                name: ''
            },
            dialogVisible: false,
            formLabelWidth: '100px',
            batchdeleteList: [],
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],
            loading: true
        }
    },
    activated() {},
    created() {
        // this.getList()
    },
    filters: {
        tissueTypes(val) {
            if (val == 1) {
                return '男'
            } else if (val == 2) {
                return '女'
            } else {
                return val
            }
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                // this.resetForm('form')
            }
        }
    },
    methods: {
        init() {
            this.dialogVisible = true
            this.getList()
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            let url = '/baserentroute/list'
            this.$http.post(url, option).then(
                ({ detail }) => {
                    this.dataList = detail.list
                    this.total = detail.total
                    this.loading = false
                },
                () => {
                    this.loading = false
                }
            )
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 勾选数据
        selectchange(selection) {
            //   console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            //   console.log(selection)
            this.batchdeleteList = selection
        },
        // 单选
        handleCurrentSelectChange(val) {
            this.dialogVisible = false
            this.$emit('updateSite', val)
        },
        // 确定选择
        selectSure() {
            if (this.batchdeleteList.length === 0) {
                this.$message({
                    message: '请选择司机',
                    type: 'warning'
                })
            } else {
                this.dialogVisible = false
                this.$emit('updateSite', this.batchdeleteList)
            }
        }
    }
}

</script>
