<template>
	<div class="add_update_role">
		<jy-dialog
			type="table"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_table"
			:width="global.dialogTabelWidth"
			append-to-body
		>
			<div>
				<jy-query ref="form" :model="form">
					<jy-query-item prop="vehicleNo" label="车牌号:" :span="1.5">
						<el-input v-model="form.vehicleNo"></el-input>
					</jy-query-item>
					<template slot="search">
						<el-button type="primary" @click="oncheck">查询</el-button>
						<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
					</template>
				</jy-query>
				<!-- @select-all="selectallchange"@select="selectchange"-->
				<jy-table max-height="595" :data="dataList" v-loading="loading" @row-click="handleCurrentSelectChange">
					<jy-table-column type="index" label="序号" width="60"></jy-table-column>
					<!-- <jy-table-column type="selection" width="40"></jy-table-column> -->
					<jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
					<jy-table-column prop="vin" label="VIN"></jy-table-column>
					<jy-table-column prop="routeName" label="所属线路"></jy-table-column>
					<jy-table-column prop="stationNames" label="关联场站"></jy-table-column>
					<jy-table-column prop="orgName" label="所属机构"></jy-table-column>
				</jy-table>
				<jy-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="total"
				></jy-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				<!-- <el-button @click="cancelFun">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>-->
			</div>
		</jy-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			type: "",
			isCheck: false,
			title: "车辆选择",
			form: {
				vehicleNo: ""
			},
			dialogVisible: false,
			formLabelWidth: "100px",

			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			loading: true
		};
	},
	activated() {},
	created() {
		// this.getList()
	},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("form");
			}
		}
	},
	methods: {
		init() {
			this.dialogVisible = true;
			this.getList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		getList() {
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			console.log(option);
			let url = "baseinfovehicle/list";
			this.$http.post(url, option).then(({ detail }) => {
				// console.log(detail)
				this.dataList = detail.list;
				this.total = detail.total;
				this.loading = false;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},

		// 单选
		handleCurrentSelectChange(val) {
			console.log(val);
			this.dialogVisible = false;
			this.$emit("updateSite", val);
		}
	}
};
</script>
<style type="text/css" lang="scss" scoped="add_update_role"></style>
