<template>
	<div class="add_update_role">
		<jy-dialog
			type="table"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_table"
			:width="global.dialogTabelWidth"
			append-to-body
		>
			<div>
				<jy-query ref="form" :model="form">
					<jy-query-item prop="driverName" label="司机姓名:" :span="1.5">
						<el-input placeholder="请输入" v-model="form.driverName"></el-input>
					</jy-query-item>
					<jy-query-item prop="jobNum" label="员工工号:" :span="1.5">
						<el-input placeholder="请输入" v-model="form.jobNum"></el-input>
					</jy-query-item>
					<template slot="search">
						<el-button type="primary" @click="oncheck">查询</el-button>
						<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
					</template>
				</jy-query>
				<jy-table
					max-height="595"
					:data="dataList"
					@cell-click="handleCurrentSelectChange"
					v-loading="loading"
					:row-style="setRowStyle"
				>
					<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
					<!-- <jy-table-column type="selection" width="40"></jy-table-column> -->
					<jy-table-column prop="name" label="司机姓名"></jy-table-column>
					<jy-table-column prop="jobNum" label="员工工号"></jy-table-column>
					<jy-table-column prop="vehicleStrs" label="已关联车辆"></jy-table-column>
					<jy-table-column prop="gender" label="性别">
						<template slot-scope="scope">{{ scope.row.gender | tissueTypes }}</template>
					</jy-table-column>
					<jy-table-column prop="phone" label="联系电话"></jy-table-column>
					<jy-table-column prop="serviceState" label="供职状态"></jy-table-column>
				</jy-table>
				<jy-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="total"
				></jy-pagination>
			</div>
			<div slot="footer" class="dialog-footer"></div>
		</jy-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			type: "",
			isCheck: false,
			title: "司机选择",
			form: {
				driverName: "",
				jobNum: ""
			},
			dialogVisible: false,
			formLabelWidth: "100px",
			batchdeleteList: [],
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			loading: true
		};
	},
	activated() {},
	created() {
		// this.getList()
	},
	filters: {
		tissueTypes(val) {
			if (val === "1") {
				return "男";
			} else if (val === "0") {
				return "女";
			} else {
				return val;
			}
		}
	},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				// this.resetForm('form')
			}
		}
	},
	methods: {
		init() {
			this.dialogVisible = true;
			this.getList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		// 设置行内元素样式
		setRowStyle({ row }) {
			//   if (row.vehicleStrs) {
			//     console.log(1)
			//     return 'background-color: #E6E6FA;opacity: 0.8;'
			//   }
			if (row.vehicleStrs) {
				return { "background-color": "#E6E6FA", opacity: "0.8" };
			}
		},
		getList() {
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
				serviceState: 1
			};
			console.log(option);
			let url = "/baseinfodriver/list";
			this.$http.post(url, option).then(
				({ detail }) => {
					// console.log(detail)
					this.dataList = detail.list;
					this.total = detail.total;
					this.loading = false;
				},
				() => {
					this.loading = false;
				}
			);
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 勾选数据
		selectchange(selection) {
			//   console.log(selection)
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			//   console.log(selection)
			this.batchdeleteList = selection;
		},
		// 单选
		handleCurrentSelectChange(val) {
			this.dialogVisible = false;
			this.$emit("updateSite", val);
		},
		// 确定选择
		selectSure() {
			if (this.batchdeleteList.length === 0) {
				this.$message({
					message: "请选择司机",
					type: "warning"
				});
			} else {
				this.dialogVisible = false;
				this.$emit("updateSite", this.batchdeleteList);
			}
		}
	}
};
</script>
