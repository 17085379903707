<template>
    <div class="addWorktimeCollection">
        <jy-dialog type="table"
            :title="title"
            :visible.sync="dialogVisible"
            custom-class="dialog_table"
            :width="global.dialogTabelWidth"
        >
            <div>
                <el-form
                    :model="diaform"
                    ref="dialogForm"
                    :rules="rules"
                    :disabled="isCheck"
                    :label-width="formLabelWidth"
                >
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="线路组织:" prop="routeName">
                                <div>
                                    <el-input
                                        placeholder="请选择"
                                        v-model="diaform.routeName"
                                        @focus="selectInstitutions"
                                    ></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="是否跨线路:" prop="isScheduleShift">
                                <el-radio v-model="diaform.isScheduleShift" label="1">是</el-radio>
                                <el-radio v-model="diaform.isScheduleShift" label="0">否</el-radio>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="车牌号:" prop="actualVehicleNo">
                                <div v-if="!checked">
                                    <el-input
                                        placeholder="请选择"
                                        v-model="diaform.actualVehicleNo"
                                        @focus="selectcarDriversTree"
                                    ></el-input>
                                </div>
                                <div v-else>
                                    <el-input
                                        v-model="diaform.actualVehicleNo"
                                        placeholder="请输入"
                                        clearable
                                        @focus="carSelect"
                                    ></el-input>
                                </div>
                                <!-- <el-input v-else v-model="diaform.actualVehicleNo" placeholder="请输入" clearable @focus="carSelect"></el-input> -->
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="计划车牌号:" prop="planVehicleNo">
                                <el-input
                                    v-model="diaform.planVehicleNo"
                                    clearable
                                    :disabled="true"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="司机:" prop="actualDriverName">
                                <div v-if="!checked">
                                    <el-input
                                        placeholder="请选择"
                                        @focus="selectDriversTree"
                                        v-model="diaform.actualDriverName"
                                    ></el-input>
                                </div>
                                <div @click="siteSelect" v-else>
                                    <el-input
                                        v-model="diaform.actualDriverName"
                                        placeholder="请输入"
                                        clearable
                                    ></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="计划司机:" prop="planDriverName">
                                <el-input
                                    v-model="diaform.planDriverName"
                                    clearable
                                    :disabled="true"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <!-- <el-form-item label="开始时间:" prop="beginTime">
                                <el-date-picker v-model="diaform.beginTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                            </el-form-item>-->
                            <el-form-item label="日期:" prop="operateDate">
                                <el-date-picker
                                    v-model="diaform.operateDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd"
                                ></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <!-- <el-form-item label="结束时间:" prop="endTime">
                                <el-date-picker v-model="diaform.endTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                            </el-form-item>-->
                            <el-form-item label="开始时间:" prop="operateStartTime">
                                <el-time-picker
                                    v-model="diaform.operateStartTime"
                                    placeholder="选择时间"
                                    value-format="HH:mm"
                                    :picker-options="pickerOptions"
                                ></el-time-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="结束时间:" prop="operateEndTime">
                                <el-time-picker
                                    v-model="diaform.operateEndTime"
                                    placeholder="选择时间"
                                    value-format="HH:mm"
                                    :picker-options="pickerOptions"
                                ></el-time-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="非营运类型:" prop="nonOperateType">
                                <el-select v-model="diaform.nonOperateType" placeholder="请选择">
                                    <el-option
                                        v-for="item in typeList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="运行里程:" prop="runMileage">
                                <el-input v-model="diaform.runMileage" clearable></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="备注:" prop="operateRemark">
                        <el-input
                            v-model="diaform.operateRemark"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入内容"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="!isCheck">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="save('dialogForm')">确 定</el-button>
                </div>
            </div>
        </jy-dialog>
        <cardriver-select ref="siteSelectDialog" @updateSite="onSite"></cardriver-select>
        <depotcar-select ref="carSelectDialog" @updateSite="onCarSite"></depotcar-select>
        <institutions-tree
            ref="institutions"
            @addInstitutions="addInstitutions"
            url="/waybillOperate/getTree"
            title="选择线路组织"
            :defaultProps="props"
        ></institutions-tree>
        <institutions-tree
            ref="carDriversTree"
            @addInstitutions="addCarDriversTree"
            url="/waybillOperate/getRouteRevealTree"
            title="选择车辆"
            :option="carOption"
            :isActivated="false"
            :defaultProps="props"
        ></institutions-tree>
        <institutions-tree
            ref="driversTree"
            @addInstitutions="addDriversTree"
            url="/waybillOperate/getRouteRevealTree"
            title="选择司机"
            :option="dirverOption"
            :isActivated="false"
            :defaultProps="props"
        ></institutions-tree>
    </div>
</template>
<script>
const pickerOptions = {
  format: 'HH:mm'
}
import {typeNonOperational} from '@/common/js/wordbook'
const typeList = typeNonOperational()
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import cardriverSelect from '@/components/pages/admin/resourcesAllocation/carDriver/cardriverSelect'
import depotcarSelect from '@/components/pages/admin/resourcesAllocation/depotCar/depotcarSelectOne'
export default {
  data() {
    return {
      type: '',
      carOption: {
        index: '',
        routeId: ''
      },
      dirverOption: {
        index: '',
        routeId: ''
      },
      isCheck: false,
      isUpdate: false,
      title: '',
      lineList: [],
      plateList: [],
      driverList: [],
      typeList,
      props: {
        children: 'children',
        label: 'text'
      },
      checked: false,
      diaform: {
        actualDriverId: '',
        actualDriverName: '',
        actualVehicleId: '',
        actualVehicleNo: '',
        planVehicleId: '',
        planVehicleNo: '',
        planDriverId: '',
        planDriverName: '',
        routeName: '',
        routeId: '',
        isScheduleShift: '0',
        checked: false,
        operateDate: '',
        // beginTime: '',
        // endTime: '',
        operateStartTime: '',
        operateEndTime: '',
        runMileage: '',
        nonOperateType: '',
        operateRemark: ''
      },
      rules: {
        routeName: [
          {
            required: true,
            message: '请选取线路组织',
            trigger: ['change']
          }
        ],
        actualVehicleNo: [
          {
            required: true,
            message: '请选取车牌',
            trigger: ['change']
          }
        ],
        actualDriverName: [
          {
            required: true,
            message: '请选取司机',
            trigger: ['change']
          }
        ],
        // beginTime: [
        //     {
        //         required: true,
        //         message: '请选取开始时间',
        //         trigger: 'change'
        //     }
        // ],
        // endTime: [
        //     {
        //         required: true,
        //         message: '请选取结束时间',
        //         trigger: 'change'
        //     }
        // ],
        operateDate: [
          {
            required: true,
            message: '请选取营运日期',
            trigger: 'change'
          }
        ],
        operateStartTime: [
          {
            required: true,
            message: '请选取开始时间',
            trigger: 'change'
          }
        ],
        operateEndTime: [
          {
            required: true,
            message: '请选取结束时间',
            trigger: 'change'
          }
        ],
        runMileage: [
          {
            required: true,
            message: '请填写GPS里程',
            trigger: 'blur'
          }
        ],
        nonOperateType: [
          {
            required: true,
            message: '请选取非营运类型',
            trigger: 'change'
          }
        ]
      },
      dialogVisible: false,
      formLabelWidth: '150px',
      pickerOptions
    }
  },
  components: {
    cardriverSelect,
    depotcarSelect,
    institutionsTree
  },
  activated() {},
  created() {},
  watch: {
    type: function(val) {
      let t = ''
      let isCheck = false
      let isUpdate = false
      switch (val) {
        case 'add':
          t = '补录非营运'
          break
        case 'update':
          t = '修改非营运'
          isUpdate = true
          break
        case 'view':
          t = '查看非营运'
          isCheck = true
          break
        default:
          t = '补录非营运'
      }
      this.isCheck = isCheck
      this.isUpdate = isUpdate
      this.title = t
    },
    dialogVisible(newview) {
      if (!newview) {
        this.resetForm('dialogForm')
        this.diaform.actualVehicleId = ''
      }
    },
    'diaform.isScheduleShift': function(val) {
      if (parseInt(val) == 1) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    'diaform.routeId': function(val, oldVal) {
      if (val != oldVal && val) {
        this.carOption = {
          index: 1,
          routeId: val
        }
        this.dirverOption = {
          index: 2,
          routeId: val
        }
      }
    },
    'diaform.actualVehicleNo'(val) {
      if (!val) {
        this.diaform.actualVehicleId = ''
      }
    }
  },
  methods: {
    init(option, type) {
      this.type = type ? type : 'add'
      this.dialogVisible = true
      if (option) {
        this.$nextTick(() => {
          if (this.type != 'add') {
            //更新或查看
            this.diaform = {
                      ...this.diaform,
                      ...JSON.parse(JSON.stringify(option))
                    }
          } else {
            //新增
            let addOption = JSON.parse(JSON.stringify(option))
            this.diaform.routeId = addOption.routeId
            this.diaform.routeName = addOption.routeName
          }
        })
      }
    },
    // 组织
    selectInstitutions() {
      this.$refs.institutions.init()
    },
    addInstitutions(data) {
      this.diaform.routeId = data.id
      this.diaform.routeName = data.text
    },
    //车辆组织
    selectcarDriversTree(e) {
      $(e.target).blur()
      if (this.carOption.routeId) {
        this.$refs.carDriversTree.init()
      } else {
        this.$alert('请先选择线路', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            console.log(action)
          }
        })
      }
    },
    addCarDriversTree(data) {
      this.diaform.actualVehicleId = data.id
      this.diaform.actualVehicleNo = data.text
    },
    //司机组织
    selectDriversTree(e) {
      $(e.target).blur()
      if (this.carOption.routeId) {
        this.$refs.driversTree.init()
      } else {
        this.$alert('请先选择线路', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            console.log(action)
          }
        })
      }
    },
    addDriversTree(data) {
      this.diaform.actualDriverId = data.id
      this.diaform.actualDriverName = data.text
    },
    // 车辆选择
    carSelect() {
      this.$refs.carSelectDialog.init()
    },
    onCarSite(siteArr) {
      // 选择的车辆及对应的VIN显示
      console.log(siteArr)
      siteArr = JSON.parse(JSON.stringify(siteArr))
      this.diaform.actualVehicleNo = siteArr.vehicleNo
      this.diaform.actualVehicleId = siteArr.vehicleId
    },
    // 司机选择
    siteSelect() {
      this.$refs.siteSelectDialog.init()
    },
    onSite(siteArr) {
      // 选择的车辆及对应的VIN显示
      siteArr = JSON.parse(JSON.stringify(siteArr))
      console.log(siteArr)
      this.diaform.actualDriverName = siteArr.name
      this.diaform.actualDriverId = siteArr.driverId
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log(this.diaform)
          let url = '/waybillNonOperateSettle/'
          if (this.type == 'add') {
            url = url + 'add'
          } else {
            url = url + 'modify'
          }
          let option = this.diaform
          this.$http.post(url, option).then(res => {
            this.$message({
              type: 'success',
              message: res.msg
            })
            this.dialogVisible = false
            this.$emit('updateList')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
