<template>
	<div class="waybillCollection">
		<el-row class="search">
			<el-col :span="24" class="search_button">
				<el-row>
					<el-col :span="24">
						<el-button v-if="btnexist('/workHours/addRecord')" type="primary" @click="addCollectionWay">补录</el-button>
						<el-button v-if="btnexist('/workHours/batchUpdate')" type="primary" @click="batchupdate()">批量修改</el-button>
						<el-button v-if="btnexist('/workHours/batchDelete')" type="primary" @click="batchdelete()">批量删除</el-button>
						<el-button v-if="btnexist('/workHours/batchVerity')" type="primary" @click="batchaudit()">批量提交审核</el-button>
						<!-- <el-button type="primary" @click="onexport()">导出</el-button> -->
					</el-col>
				</el-row>
			</el-col>
		</el-row>
		<!-- v-popover:jy_popover @cell-mouse-enter="tableHover"-->
		<jy-table
			max-height="595"
			ref="multipleTable"
			tooltip-effect="dark"
			@select-all="selectallchange"
			@select="selectchange"
			:data="dataList"
		>
			<jy-table-column type="index" label="序号" width="60" fixed="left" align="center"></jy-table-column>
			<jy-table-column type="selection" width="40" fixed="left"></jy-table-column>
			<jy-table-column prop="routeName" label="所属线路" min-width="120" fixed="left"></jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="180" fixed="left"></jy-table-column>
			<jy-table-column prop="operateDate" label="营运日期" min-width="120" fixed="left"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="120" fixed="left"></jy-table-column>
			<jy-table-column prop="driverName" label="司机" min-width="120" fixed="left"></jy-table-column>
			<jy-table-column prop="loginTime" label="登录时间" min-width="120"></jy-table-column>
			<jy-table-column prop="logoutTime" label="签退时间" min-width="120"></jy-table-column>
			<jy-table-column prop="workingHours" label="工作时长" min-width="120"></jy-table-column>
			<jy-table-column prop="isScheduleShift" label="是否跨线路" min-width="120">
				<template slot-scope="scope">{{ scope.row.isScheduleShift == "0" ? "否" : "是" }}</template>
			</jy-table-column>
			<jy-table-column prop="stateName" label="状态" min-width="120">
				<template slot-scope="scope">{{ scope.row.stateName | statusTypes }}</template>
			</jy-table-column>
			<jy-table-column prop="firstLoginTime" label="原始登录时间" min-width="120"></jy-table-column>
			<jy-table-column prop="firstLogoutTime" label="原始签退时间" min-width="120"></jy-table-column>
			<!-- <jy-table-column prop="morLeaveTime" label="早班出场时间" min-width="120"></jy-table-column>
            <jy-table-column prop="eveLeaveTime" label="晚班进场时间" min-width="120"></jy-table-column> -->
			<jy-table-column prop="runMileage" label="运行里程(km)" min-width="120"></jy-table-column>
			<jy-table-column prop="operateUser" label="路单操作人" min-width="120"></jy-table-column>
			<jy-table-column prop="operateTime" label="路单操作时间" min-width="120"></jy-table-column>
			<jy-table-column prop="operateRemark" label="路单操作备注" min-width="200"></jy-table-column>
			<jy-table-column prop="auditStateName" label="路单审核状态" min-width="120">
				<template slot-scope="scope">{{ scope.row.auditStateName | tissueTypes }}</template>
			</jy-table-column>
			<jy-table-column prop="auditUser" label="路单审核人" min-width="120"></jy-table-column>
			<jy-table-column prop="auditTime" label="路单审核时间" min-width="120"></jy-table-column>
			<jy-table-column prop="auditRemark" label="路单审核备注" min-width="200"></jy-table-column>
			<jy-operate v-bind:list="operateList" width="200"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<div class="jy_popover">
			<el-popover placement="top" title="路单异常" width="180" trigger="hover" ref="jy_popover">
				<div class="popover_content" v-for="item in statusDate" :key="item.text">
					<div :style="{ background: item.status_color }" class="popover_color"></div>
					<div v-text="item.text"></div>
				</div>
			</el-popover>
		</div>
		<add-worktime-collection ref="addWorktimeCollection" @updateList="oncheck"></add-worktime-collection>
		<batch-update-worktime ref="batchUpdateWorktime" @updateList="oncheck"></batch-update-worktime>
		<track-replay ref="trackReplay"></track-replay>
	</div>
</template>
<script>
import addWorktimeCollection from "@/components/pages/admin/waybillManagement/collection/workTimeCollection/addWorktimeCollection";
import batchUpdateWorktime from "@/components/pages/admin/waybillManagement/collection/workTimeCollection/batchUpdateWorktime";
import trackReplay from "@/components/pages/admin/waybillManagement/trackReplay/trackReplay";

export default {
	data() {
		return {
			statusDate: [],
			batchdeleteList: [],
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			operateList: []
		};
	},
	props: {
		formData: {
			type: Object,
			default: function () {
				return {};
			}
		}
	},
	filters: {
		tissueTypes(val) {
			if (val === 0) {
				return "待审核";
			} else if (val === 1) {
				return "审核不通过";
			} else if (val === 2) {
				return "审核通过";
			} else {
				return val;
			}
		},
		statusTypes(val) {
			if (val === 0) {
				return "正常";
			} else if (val === 1) {
				return "补录";
			} else if (val === 3) {
				return "调整";
			} else {
				return val;
			}
		}
	},
	watch: {},

	components: {
		addWorktimeCollection,
		batchUpdateWorktime,
		trackReplay
	},

	computed: {},

	beforeMount() {},

	mounted() {},

	activated() {
		this.getList();
	},

	created() {
		this.getList();
		this.setOperateList();
	},

	methods: {
		setOperateList() {
			const vm = this;
			let l = [
				{
					name: "轨迹回放",
					icon: "el-icon-edit-outline",
					fun: this.trackReplay,
					isShow() {
						return vm.btnexist("/workHours/trackPlayback");
					}
				},
				{
					name: "路单修改",
					icon: "el-icon-edit-outline",
					fun: this.addUdate,
					isShow: function (row) {
						if (parseInt(row.auditState) != 2 && vm.btnexist("/workHours/update")) {
							return true;
						} else {
							return false;
						}
					}
				},
				{
					name: "提交审核",
					icon: "el-icon-edit-outline",
					fun: this.onAudit,
					isShow: function (row) {
						if (parseInt(row.auditState) != 2 && vm.btnexist("/workHours/verity")) {
							return true;
						} else {
							return false;
						}
					}
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete,
					isShow: function (row) {
						if (parseInt(row.auditState) != 2 && vm.btnexist("/workHours/delete")) {
							return true;
						} else {
							return false;
						}
					}
				}
			];
			this.operateList = l;
		},
		// 查询数据
		getList() {
			let option = {
				...this.formData,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			//   console.log(option)
			let url = "/waybillWorkingHours/getList";
			this.$http.post(url, option).then(({ detail }) => {
				this.dataList = detail.list;
				this.total = detail.total;
				this.batchdeleteList = [];
			});
		},
		// 查询表格
		oncheck() {
			this.getList();
			console.log("更新");
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 勾选数据
		selectchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		tableHover(row) {
			console.log(row);
			this.statusDate = [
				{
					status_color: "#fbd1dd",
					text: "砍脱班"
				},
				{
					status_color: "#e8b86d",
					text: "未知班次"
				},
				{
					status_color: "#dc7b6d",
					text: "不准点"
				},
				{
					status_color: "#b386b5",
					text: "运行时长异常"
				},
				{
					status_color: "#73c180",
					text: "驾驶员异常"
				},
				{
					status_color: "#82d1df",
					text: "里程异常"
				},
				{
					status_color: "#6d91cd",
					text: "时间依据异常"
				}
			];
		},
		// 补录
		addCollectionWay() {
			this.formData.checked = false;
			this.$refs.addWorktimeCollection.init(this.formData, "add");
		},
		// 批量修改工作时长
		batchupdate() {
			if (this.batchdeleteList.length == 0) {
				this.$message({
					type: "warning",
					message: "请先选取数据"
				});
			} else {
				this.$refs.batchUpdateWorktime.init(this.batchdeleteList);
			}
		},
		// 修改路单
		addUdate(row) {
			this.$refs.addWorktimeCollection.init(row, "update");
		},
		// 批量班次调整
		batchadjust() {
			this.$refs.shiftAdjust.init(this.formData, "batch");
		},
		// 班次调整
		shiftAdjust() {
			this.$refs.shiftAdjust.init(this.formData, "single");
		},
		// 批量删除
		batchdelete() {
			if (this.batchdeleteList.length == 0) {
				this.$message({
					type: "warning",
					message: "请先选取数据"
				});
			} else {
				this.$confirm("是否删除所选中的记录?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						let ids = [];
						this.batchdeleteList.forEach(item => {
							ids.push(item.workingHoursId);
						});
						let option = { ids: ids };
						this.deleteRecord(option);
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除"
						});
					});
			}
		},
		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该文件?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = { ids: [row.workingHoursId] };
					this.deleteRecord(option);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		deleteRecord(supplierIdAry) {
			this.$http.post("/waybillWorkingHours/delete", supplierIdAry).then(data => {
				if (data.code == "0") {
					this.$message.success(data.msg);
					this.getList();
				} else {
					this.$message.error(data.msg);
				}
			});
		},
		// 批量提交审核
		batchaudit() {
			if (this.batchdeleteList.length == 0) {
				this.$message({
					type: "warning",
					message: "请先选取数据"
				});
			} else {
				let ids = [];
				this.batchdeleteList.forEach(item => {
					ids.push(item.workingHoursId);
				});
				let option = { ids: ids };
				this.auditRecord(option);
			}
		},
		// 提交审核
		onAudit(row) {
			let option = { ids: [row.workingHoursId] };
			this.auditRecord(option);
		},
		auditRecord(supplierIdAry) {
			this.$http.post("/waybillWorkingHours/submitAudit", supplierIdAry).then(data => {
				if (data.code == "0") {
					this.$message.success(data.msg);
					this.getList();
				} else {
					this.$message.error(data.msg);
				}
			});
		},
		// 导出
		onexport() {},
		// 轨迹回放
		trackReplay(option) {
			this.$refs.trackReplay.init(option, "workTime");
		},
		// 查看
		handlecheck(option) {
			this.$refs.addUpdateOrg.init(option, "view");
		},
		btnexist(name) {
			const parent = this.$parent.$parent.$parent;
			return parent.btnexist(name);
		}
	}
};
</script>
<style lang="scss" scoped>
.popover_content {
	display: flex;
	justify-content: flex-start;

	.popover_color {
		height: 15px;
		width: 15px;
		margin-right: 10px;
	}
}
</style>
